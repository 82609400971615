import { useRef, useState } from 'react';
import './App.css';

function App() {

  const [currentMusicDetails, setCurrentMusicDetails] = useState({
    songName: 'Pani Da Rang',
    songArtist: 'Ayushmann Khurrana',
    songSrc: './Assets/songs/128-Pani.mp3',
    songAvatar: './Assets/Images/image1.jpg'
  })

  //UseStates Variables
  const [audioProgress, setAudioProgress] = useState(0);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [musicIndex, setMusicIndex] = useState(0);
  const [musicTotalLength, setMusicTotalLength] = useState('04 : 38');
  const [musicCurrentTime, setMusicCurrentTime] = useState('00 : 00');
  const [videoIndex, setVideoIndex] = useState(0)

  const currentAudio = useRef()

  const handleMusicProgressBar = (e)=>{
    setAudioProgress(e.target.value);
    currentAudio.current.currentTime = e.target.value * currentAudio.current.duration / 100;
  }

  //Change Avatar Class
  let avatarClass = ['objectFitCover','objectFitContain','none']
  const [avatarClassIndex, setAvatarClassIndex] = useState(0)
  const handleAvatar = ()=>{
    if (avatarClassIndex >= avatarClass.length - 1) {
      setAvatarClassIndex(0)
    }else{
      setAvatarClassIndex(avatarClassIndex + 1)
    }
  }


  //Play Audio Function
  const handleAudioPlay = ()=>{
    if (currentAudio.current.paused) {
      currentAudio.current.play();
      setIsAudioPlaying(true)
    }else{
      currentAudio.current.pause();
      setIsAudioPlaying(false)
    }
  }

  const musicAPI = [
    {
      songName: 'Pani Da Rang',
      songArtist: 'Ayushmann Khurrana',
      songSrc: './Assets/songs/128-Pani.mp3',
      songAvatar: './Assets/Images/image1.jpg'
    },
    {
      songName: 'Tu Laung Main Elaachi',
      songArtist: 'Tulsi kumar',
      songSrc: './Assets/songs/Tu Laung Main Elaachi - Luka Chuppi 128 Kbps.mp3',
      songAvatar: './Assets/Images/128Tu Laung Main Elaachi - Luka Chuppi 128 Kbps.jpg'
    },
    {
      songName: 'Bhagwan Hai Kahan- PK',
      songArtist: 'Shantanu',
      songSrc: './Assets/songs/Bhagwan Hai Kahan Re Tu - PK 128 Kbps.mp3',
      songAvatar: './Assets/Images/128Bhagwan Hai Kahan Re Tu - PK 128 Kbps.jpg'
    },
    {
      songName: 'Catch Me If I Fall',
      songArtist: 'TEGNENT',
      songSrc: './Assets/songs/Catch Me If I Fall - NEFFEX.mp3',
      songAvatar: './Assets/Images/image3.jpg'
    },
    {
      songName: 'Salaam Rocky Bhai',
      songArtist: 'Vijay',
      songSrc: './Assets/songs/Salaam Rocky Bhai - KGF Chapter 1 128 Kbps.mp3',
      songAvatar: './Assets/Images/kgf.jpg'
    },
    {
      songName: 'Raftaarein - Ra-One',
      songArtist: 'Vishal-Shekhar',
      songSrc: './Assets/songs/Raftaarein - Ra-One 128 Kbps.mp3',
      songAvatar: './Assets/Images/128Raftaarein - Ra-One 128 Kbps.jpg'
    },
    {
      songName: 'Sultan',
      songArtist: 'Vijay',
      songSrc: './Assets/songs/Sultan - KGF Chapter 1 128 Kbps.mp3',
      songAvatar: './Assets/Images/kgf.jpg'
    },
    {
      songName: 'Soch (Slowed+Reverbed)',
      songArtist: 'Hardy Sandhu',
      songSrc: './Assets/songs/SOCH(Slowed+Reverbed) __ Hardy Sandhu.webm',
      songAvatar: './Assets/Images/image6.jpg'
    },
    {
      songName: 'Bandeya',
      songArtist: 'Arijit Singh',
      songSrc: './Assets/songs/bollywood_DJ 2018 - Bandeya.mp3',
      songAvatar: './Assets/Images/Bandeya_(Dil_Juunglee)_Arijit_Singh.jpg'
    },
    {
      songName: 've Kamleya ',
      songArtist: 'Arijit Singh- Pritam',
      songSrc: './Assets/songs/Ve Kamleya - Rocky Aur Rani Kii Prem Kahaani 128 Kbps.mp3',
      songAvatar: './Assets/Images/128Ve Kamleya.jpg'
    },
    {
      songName: 'Besabriyaan - M.S. Dhoni',
      songArtist: 'Armaan malik',
      songSrc: './Assets/songs/Besabriyaan - M.S. Dhoni - The Untold Story 128 Kbps.mp3',
      songAvatar: './Assets/Images/128Besabriyaan.jpg'
    },
    {
      songName: 'Apna Bana Le',
      songArtist: 'Arijit Singh',
      songSrc: './Assets/songs/Apna Bana Le - Full Audio _ Bhediya _ Varun Dhawan, Kriti Sanon_ Sachin-Jigar,Arijit Singh,Amitabh B.webm',
      songAvatar: './Assets/Images/image7.jpg'
    },
    {
      songName: 'Tauba Tauba ',
      songArtist: 'Karan aljiya',
      songSrc: './Assets/songs/tauba.mp3',
      songAvatar: './Assets/Images/tauba.jpg'
    },
    
    {
      songName: 'Mahiye Jinna Sohna',
      songArtist: 'Darshan Rawal',
      songSrc: './Assets/songs/mahiye.mp3',
      songAvatar: './Assets/Images/mahiye.jpg'
    },
    {
      songName: 'Paon Ki Jutti Jaani',
      songArtist: 'Jaani-Jyoti',
      songSrc: './Assets/songs/paon.mp3',
      songAvatar: './Assets/Images/paon.jpg'
    },
    {
      songName: 'Angreji Beat',
      songArtist: 'Yo Yo Honey Singh',
      songSrc: './Assets/songs/angrezi.mp3',
      songAvatar: './Assets/Images/angrezi.jpg'
    },
    {
      songName: 'Yaarr Ni Milyaa',
      songArtist: 'Harrdy Sandhu',
      songSrc: './Assets/songs/yaar.mp3',
      songAvatar: './Assets/Images/Yaarr-Ni.jpg'
    }
  ]

  const handleNextSong = ()=>{
    if (musicIndex >= musicAPI.length - 1) {
      let setNumber = 0;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    }else{
      let setNumber = musicIndex + 1;
      setMusicIndex(setNumber)
      updateCurrentMusicDetails(setNumber);
    }
  }


  const handlePrevSong = ()=>{
    if (musicIndex === 0) {
      let setNumber = musicAPI.length - 1;
      setMusicIndex(setNumber);
      updateCurrentMusicDetails(setNumber);
    }else{
      let setNumber = musicIndex - 1;
      setMusicIndex(setNumber)
      updateCurrentMusicDetails(setNumber);
    }
  }

  const updateCurrentMusicDetails = (number)=>{
    let musicObject = musicAPI[number];
    currentAudio.current.src = musicObject.songSrc;
    currentAudio.current.play();
    setCurrentMusicDetails({
      songName: musicObject.songName,
      songArtist: musicObject.songArtist,
      songSrc: musicObject.songSrc,
      songAvatar: musicObject.songAvatar
    })
    setIsAudioPlaying(true);
  }

  const handleAudioUpdate = ()=>{
    //Input total length of the audio
    let minutes = Math.floor(currentAudio.current.duration / 60);
    let seconds = Math.floor(currentAudio.current.duration % 60);
    let musicTotalLength0 = `${minutes <10 ? `0${minutes}` : minutes} : ${seconds <10 ? `0${seconds}` : seconds}`;
    setMusicTotalLength(musicTotalLength0);

    //Input Music Current Time
    let currentMin = Math.floor(currentAudio.current.currentTime / 60);
    let currentSec = Math.floor(currentAudio.current.currentTime % 60);
    let musicCurrentT = `${currentMin <10 ? `0${currentMin}` : currentMin} : ${currentSec <10 ? `0${currentSec}` : currentSec}`;
    setMusicCurrentTime(musicCurrentT);

    const progress = parseInt((currentAudio.current.currentTime / currentAudio.current.duration) * 100);
    setAudioProgress(isNaN(progress)? 0 : progress)
  }


  const vidArray = ['./Assets/Videos/vid1.mp4','./Assets/Videos/vid2.mp4','./Assets/Videos/vid4.mp4','./Assets/Videos/vid3.mp4'];

  const handleChangeBackground = ()=>{
    if (videoIndex >= vidArray.length - 1) {
      setVideoIndex(0);
    }else{
      setVideoIndex(videoIndex + 1)
    }
  }


  return (
    <>
    <div className="container">
      <audio src='./Assets/songs/128-Pani.mp3' ref={currentAudio} onEnded={handleNextSong} onTimeUpdate={handleAudioUpdate}></audio>
      <video src={vidArray[videoIndex]} loop muted autoPlay className='backgroundVideo'></video>
      <div className="blackScreen"></div>
      <div className="music-Container">
        <p className='musicPlayer'>Musicx</p>
        <p className='music-Head-Name'>{currentMusicDetails.songName}</p>
        <p className='music-Artist-Name'>{currentMusicDetails.songArtist}</p>
        <img src={currentMusicDetails.songAvatar} className={avatarClass[avatarClassIndex]} onClick={handleAvatar} alt="song Avatar" id='songAvatar'/>
        <div className="musicTimerDiv">
          <p className='musicCurrentTime'>{musicCurrentTime}</p>
          <p className='musicTotalLenght'>{musicTotalLength}</p>
        </div>
        <input type="range" name="musicProgressBar" className='musicProgressBar' value={audioProgress} onChange={handleMusicProgressBar} />
        <div className="musicControlers">
         <i className='fa-solid fa-backward musicControler' onClick={handlePrevSong}></i>
          <i className={`fa-solid ${isAudioPlaying? 'fa-pause-circle' : 'fa-circle-play'} playBtn`} onClick={handleAudioPlay}></i>
          <i className='fa-solid fa-forward musicControler' onClick={handleNextSong}></i>
          </div>
      </div>
      <div className="changeBackBtn" onClick={handleChangeBackground}>
        Update Background
      </div>
      <a href="https://jprashant.in/" title='Info' className='youtube-Subs'>
        <img src="./Assets/Images/pj.jpeg" alt="Youtube Logo"/>
        <p>
Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 2024 - <b>Prashant Jha</b>
</p>
      </a>
      
    </div>
    </>
  );
}

export default App;
